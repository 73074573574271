import type { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import type { CheckoutProps, CommonProps } from '../../types/common';
import { getUserData } from '../../utils/user';

interface AppProps extends CommonProps, CheckoutProps {}

const createController: CreateControllerFn = async ({ flowAPI }: ControllerParams) => {
  const { setProps: untypedSetProps, wixCodeApi } = flowAPI.controllerConfig;

  const noop = () => {};
  const setProps: (props: Partial<AppProps>) => void = untypedSetProps;

  return {
    async pageReady() {
      setProps({
        user: getUserData(wixCodeApi.user.currentUser),
        biCheckoutStage: noop,
      });
    },
  };
};

export default createController;
